import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { WorkersCompMeta } from "../data/seo-meta"
import CallToAction from "../components/call-to-action"

import rooferImg from "../images/wc/roofer.svg"
import plusIcon from "../images/common/plus_icon.svg"
import workersCompCoverage from "../images/wc/wc_coverage_dec_2023.png"
import hotAirBalloon from "../images/common/hot_air_ballon.svg"

const highlights = [
  {
    topic: "Medical expenses",
    content: "Everything from examinations and treatments to rehabilitation.",
  },
  {
    topic: "Employers liability",
    content:
      "Protects your business from financial losses if an employee files a lawsuit against you.",
  },
  {
    topic: "Loss wages",
    content:
      "Enables employees to fully recover from an injury before returning to work.",
  },
  {
    topic: "Survivor benefits",
    content:
      "Supports families by covering funeral costs and income replacement.",
  },
]

const WorkersCompPage = () => {
  return (
    <Layout>
      <SEO
        description={WorkersCompMeta.description}
        path={WorkersCompMeta.path}
        title={WorkersCompMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-36">
          <div className="md:pr-24">
            <h1 className="h1 mb-8">Workers’ Comp</h1>
            <p className="p1">
              Agents experience a frictionless quote and bind journey in under 5
              minutes – we’ve made purchasing policies <em>that</em> easy.
              Connect with us to learn about becoming a distribution partner.
            </p>
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </div>
          <img src={rooferImg} alt="Worker on a roof" />
        </div>

        <h2 className="h2 mb-20">Highlights include:</h2>
        <div className="grid gap-y-8 gap-x-12 lg:gap-x-20 mb-20 md:grid-cols-4">
          {highlights.map((point) => (
            <div
              className="relative pl-8 pr-16 py-16 bg-[#f8f8f8] rounded-2xl drop-shadow"
              key={point.topic}
            >
              <img
                className="absolute -top-10 rounded-full badge-drop-shadow"
                src={plusIcon}
                alt="Plus icon"
              />
              <h3 className="h4 mb-4">{point.topic}</h3>
              <p className="p2">{point.content}</p>
            </div>
          ))}
        </div>

        <div className="mb-40">
          <CallToAction
            label="Download WC appetite guide"
            to="https://info.sliceinsurance.com/workers-compensation-appetite-guide-download"
            external={true}
          />
        </div>

        <div className="grid gap-20 md:grid-cols-2 mb-40">
          <div>
            <h2 className="h2 mb-8">Additional details:</h2>
            <ul className="checklist mb-12">
              <li className="p1">Industries across 65+ class codes.</li>
              <li className="p1">Growing to nationwide coverage.</li>
              <li className="p1">
                Carrier: Accident Insurance Company (AIC) & Accredited Surety
                and Casualty Company, Inc.
              </li>
            </ul>
            <CallToAction
              label="Become a distribution partner"
              to="/contact/"
            />
          </div>
          <div className={"pt-10"}>
            <img
              className="mx-auto"
              src={workersCompCoverage}
              alt="Workers comp coverage is available in 21 states"
            />
            <p className="p2">
              23 states: AL, AR, CO, CT, FL, GA, ID, IL, IN, LA, KS, MS, MO, NC, NH, NJ, NV, NM, OK, SC, TN, UT, VA + more coming soon
            </p>
          </div>
        </div>

        <div className="md:text-center mt-48">
          <img className="mx-auto" src={hotAirBalloon} alt="Hot air balloon" />
          <h2 className="h1 my-8">Become a distribution partner.</h2>
          <p className="p1">
            If you are interested in learning more, reach out.
            <br />
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default WorkersCompPage
